import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// element ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import YmapPlugin from 'vue-yandex-maps'
import VueVideoPlayer from 'vue-video-player'
import Fragment from 'vue-fragment'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import axios from 'axios'

window.videojs = VueVideoPlayer.videojs
require('video.js/dist/lang/zh-CN.js')
// 将重复的域名定义
axios.defaults.baseURL = 'https://www.margingroup.cn'
// http://www.margingroup.cn

Vue.prototype.$axios = axios

Vue.use(VueVideoPlayer)
Vue.use(Fragment.Plugin)
Vue.config.productionTip = false
Vue.use(ElementUI)
AOS.init()
Vue.use(YmapPlugin, {
  apiKey: '6ad9319c-1644-4f8e-9d7b-bc82384e1370',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
})

Vue.use(VueVideoPlayer)

router.afterEach((to, from) => {
  // console.log('33333333333333333333333333333333333333')
  setTimeout(() => {
    document.documentElement.scrollTop = 0
  }, 50)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
